import React, { useEffect, useRef } from "react"
import { cookiePolicyPageData } from "components/Data/CookiePolicyPageData"
import SeoComponent from "components/SeoComponent"
import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import PageContentWrapper from "components/Page/PageContentWrapper"

const isBrowser = () => typeof window !== "undefined"

const CookiePolicyPage = () => {
  const page = cookiePolicyPageData
  const cookiesText = useRef()
  const cookieBotId = "a4c307d0-8e43-4a81-818d-4aae77b45c8d"

  useEffect(() => {
    if (isBrowser() && cookiesText.current) {
      const cookieBotScript = document.createElement("script")

      cookieBotScript.src = `https://consent.cookiebot.com/${cookieBotId}/cd.js`
      cookieBotScript.async = true
      cookieBotScript.type = "text/javascript"
      cookieBotScript.id = "CookieDeclaration"

      cookiesText.current.appendChild(cookieBotScript)

      // return () => {
      //   cookieBotRef.removeChild(cookieBotScript)
      // }
    }
  }, [cookiesText])

  return (
    <Layout pageName="privacy">
      <SeoComponent title="Cookie Policy" description="" />
      {page && page.page_header && (
        <PageHeader fields={page.page_header} pageName="cookie-policy" />
      )}
      <div className="container x-large">
        <PageContentWrapper>
          {page && page.content && (
            <div dangerouslySetInnerHTML={{ __html: page.content }} />
          )}
          <div className="cookiebot__declaration" ref={cookiesText} />
        </PageContentWrapper>
      </div>
    </Layout>
  )
}

export default CookiePolicyPage
